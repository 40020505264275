import { Component, Vue } from 'vue-property-decorator'
import { User } from '@/Models/User'
import http from '@/services'
import ModalDelete from '@/components/ModalDelete/ModalDelete.vue'
import Modal from '@/components/Modal/index.vue'
import UsersForm from '@/components/UsersForm/index.vue'
import Search from '@/components/Search/Search.vue'
import * as Helpers from '@/helpers'
import Animation from '@/components/Animation/index.vue'
@Component({
  components: {
    ModalDelete,
    Modal,
    UsersForm,
    Search,
    Animation,
  },
})
export default class UsersView extends Vue {
  action = 'list'
  delete_modal_active = false
  objEdit: User = new User()
  currentPage = 1
  data: Array<User> = []
  userRoles: Array<any> = []
  headers: Array<any> = [
    {
      field: 'id',
      label: this.$t('id'),
      width: '40',
    },
    {
      field: 'first_name',
      label: this.$t('auth.name'),
      width: '40',
    },
    {
      field: 'middle_name',
      label: this.$t('auth.first surname'),
    },
    {
      field: 'last_name',
      label: this.$t('auth.second surname'),
    },
    {
      field: 'date_view',
      label: this.$t('auth.Date created'),
    },
  ]
  mounted() {
    this.getUsers()
  }
  edit(user: User) {
    this.objEdit = user
    this.action = 'edit'
  }

  async getUsers() {
    try {
      const res = await http.get(`/api/panel/users/`)
      if (!res.data) throw new Error(res.data)
      this.data = res.data
      /* this.data = res.data((obj: any) =>({
        ...obj,

      })) */
    } catch (error: any) {
      if (error.response && error)
        this.$buefy.toast.open({
          message: error.response.data.detail,
          type: 'is-danger',
        })
    }
  }
  add() {
    this.action = 'create'
  }

  showActiveDeleteModal(user: User) {
    console.log(user)
    this.objEdit = user
    this.delete_modal_active = !this.delete_modal_active
  }

  async deleteUsers() {
    try {
      await http.delete(`/api/panel/users/${this.objEdit.id}/`)
      this.getUsers()
      this.closeModal(false)
    } catch (error: any) {
      if (error.response && error)
        this.$buefy.toast.open({
          message: error.response.data.detail,
          type: 'is-danger',
        })
    }
  }
  closeModal(valor: boolean) {
    this.delete_modal_active = valor
  }

  closeForm() {
    this.action = 'list'
    this.getUsers()
  }
  isData(data: any) {
    this.data = data
  }
}
